import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SobreNos from "../../components/sobre-nos"
import BackgroundImage from "gatsby-background-image"
import { graphql, Link } from "gatsby"

const ReparoDeVentilador = ({ data }) => {
  return (
    <Layout showHero={true}>
      <SEO title="Conserto De Aquecedores | Cedilar" />
      <SobreNos />
      <div className="container mx-auto justify-between">
        <h1 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Reparo de Aquecedores
        </h1>
        <p className="px-4 md:px-0 pb-6">
          Com o inverno e a diminuição da temperatura em Porto Alegre é sempre
          bom ter uma forma de se sentir mais aquecido e assim driblar o frio na
          região nesta época, sendo a melhor forma a utilização de uma aquecedor
          de qualidade e trabalhe a todo vapor durante este período tão frio.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Mas, conseguir manter sempre o seu aquecedor com qualidade pode ser um
          desafio, evitando que em algum momento ele te deixe na mão e você
          possa se manter quente durante todo o frio do inverno em Porto Alegre,
          sendo preciso para isso a busca de uma assistência técnica adequada,
          como a CeDilar.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Você sabe como o nosso trabalho funciona? Tem desejo em conhecer e
          descobrir porque somos referência no mercado em assistência técnica
          para aquecedores e outros eletrodomésticos? Se sim, vamos nessa!
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Mantendo a qualidade do seu aquecedor
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Para que você consiga sempre manter a qualidade do seu aquecedor o
          ideal é cuidar para que ele esteja sempre em ótimas condições de
          limpeza e manutenção, procurando por uma assistência técnica adequada
          e que trabalhe de forma autorizada pela marca do seu produto.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Com o uso de assistência técnica da CeDilar com trabalho autorizado
          você pode ter a qualidade do seu produto mantida ao máximo, permitindo
          que a garantia que ele já traz de fábrica continue sendo mantida e
          você ainda utiliza peças de reposição fiéis e originais de fábrica,
          com o máximo de excelência no serviço ao consumidor.
        </p>
        <p className="px-4 md:px-0 pb-12">
          Contar com a assistência técnica da CeDilar é ter sempre por perto
          quem pode cuidar do seu equipamento da melhor forma possível,
          aumentando o tempo de vida-útil do produto e garantindo que você não
          seja pego de surpresa com o seu aquecedor danificado em um dos dias
          mais frios do inverno de Porto Alegre.
        </p>
      </div>
      <BackgroundImage
        Tag="section"
        fluid={data?.parqueFarroupilha?.childImageSharp?.fluid}
        className="bg-scroll h-64"
        alt="Parque Farroupilha Porto Alegre"
      />
      <div className="container mx-auto justify-between">
        <h2 className="px-4 md:px-0 pb-2 pt-12 text-xl font-bold">
          Assistência técnica de qualidade
        </h2>
        <p className="px-4 md:px-0 pb-6">
          Mas, porque a nossa assistência técnica é de qualidade? Trabalhamos no
          mercado há muito tempo, cerca de 30 anos, onde abrimos as portas ainda
          no dia 15 de maio de 1990 e continuamos até hoje sempre melhorando a
          qualidade dos nossos serviços e com o máximo de excelência em todos os
          processos.
        </p>
        <p className="px-4 md:px-0 pb-6">
          Atuamos com trabalho de assistência autorizado com as melhores marcas
          do mercado em eletrodomésticos, como a Mondial, Cardal, Sintex,
          Lorenzetti, Hydra Corona e Philips Walita. Entregar em nossas mãos o
          seu produto é garantia de segurança. Atendemos a domicílio e você pode
          ligar para o nosso telefone (51) 3217-9658.
        </p>
        <h2 className="px-4 md:px-0 pb-2 text-xl font-bold">
          Tipos de aquecedores que trabalhamos
        </h2>
        <p className="px-4 md:px-0 pb-12">
          Os tipos de aquecedores que trabalhamos em assistência técnica
          especializada que só a CeDilar pode oferecer cobre aquecedor de água
          elétrico,{" "}
          <Link to="/" className="text-blue-600">
            aquecedor a óleo e aquecedor de ar
          </Link>
          . Se você tem algum desses produtos e quer uma assistência
          especializada, basta ligar pra gente e garantir os melhores cuidados
          do mercado!
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    parqueFarroupilha: file(
      relativePath: { eq: "Parque-Farroupilha-Porto-Alegre.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default ReparoDeVentilador
